<script>
import { FilterIcon, PlusIcon, PencilIcon, TrashIcon, EyeIcon, MagnifyingGlassIcon, CircleCheckIcon, TriangleExclamationIcon, CircleXmarkIcon, SquareParkingIcon, SquareParkingSlashIcon, PercentIcon, BoxOpenIcon, BoxIcon } from '@snsicons/icons/solid'
import { XIcon } from '@heroicons/vue/24/outline'
import CopyButton from '@/components/UI/CopyButton.vue'
import ListingCompetitionButton from '@/components/Listing/ListingCompetitionButton.vue'
import ListingPriceSmallForm from '@/components/Listing/ListingPriceSmallForm.vue'
import ListingPublishModal from '@/components/Listing/ListingPublishModal.vue'
import ListingRetireModal from '@/components/Listing/ListingRetireModal.vue'
import ListingUnretireModal from '@/components/Listing/ListingUnretireModal.vue'
import ListingDeleteModal from '@/components/Listing/ListingDeleteModal.vue'
import ListingStatusBadge from '@/components/Listing/ListingStatusBadge.vue'
import ListingStrategySmallForm from '@/components/Listing/ListingStrategySmallForm.vue'

import ListingAdjustPricesModal from '@/components/Listing/ListingAdjustPricesModal.vue'
import ListingChangeStrategyModal from '@/components/Listing/ListingChangeStrategyModal.vue'

import ListingSendToMaxModal from '@/components/Listing/ListingSendToMaxModal.vue'
import ListingDiscountModal from '@/components/Listing/ListingDiscountModal.vue'

import _ from 'lodash'
import ListingSuppressedBadge from '../../components/Listing/ListingSuppressedBadge.vue'
import { nextTick } from '@vue/runtime-core'

const BASE_FILTERS = {
  term: '',
  brand: [],
  department: [],
  stock_range: [],
  price: [],
  created_at: [],
  updated_at: [],
  min_price: [],
  max_price: [],
  sales_velocity_30: [],
  quantity: 'with',
  sales_channel: [],
  strategy: [],
  under_review: '',
  status: [],
  inventory_errors: '',
  buybox_status: '',
  buybox_eligibility: '',
  fulfillment_network: '',
  competition_price: '',
  price_below_cost: '',
  cost_presence: '',
  error_category: [],
  not_repricing_status: '',
  sort_by: 'updated_at',
  sort_direction: 'desc',
}

export default {
  components: {
    BoxIcon,
    BoxOpenIcon,
    CircleCheckIcon,
    CircleXmarkIcon,
    CopyButton,
    ListingAdjustPricesModal,
    ListingChangeStrategyModal,
    ListingCompetitionButton,
    ListingDeleteModal,
    ListingDiscountModal,
    ListingPriceSmallForm,
    ListingPublishModal,
    ListingRetireModal,
    ListingSendToMaxModal,
    ListingStatusBadge,
    ListingStrategySmallForm,
    ListingSuppressedBadge,
    ListingUnretireModal,
    MagnifyingGlassIcon,
    PercentIcon,
    SquareParkingIcon,
    SquareParkingSlashIcon,
    TriangleExclamationIcon,
},
  data() {
    return {
      listings: [],
      salesChannels: [],
      strategies: [],
      selectFiltered: false,
      searchCount: {},
      loading: false,
      selected: [],
      selectAll: false,
      listingHeaders: [
        {
          text: 'SKU',
          value: 'sku',
          sortable: 'sku'
        },
        {
          text: 'Listing Title',
          value: 'name',
          sortable: 'name'
        },
        {
          text: 'Strategy',
          value: 'strategy',
        },
        {
          text: 'Buy Box',
          value: 'is_buybox_winner',
        },
        {
          text: 'Price',
          value: 'price',
          sortable: 'price_cents'
        },
        {
          text: 'Cost',
          value: 'cost',
          sortable: 'cost_cents'
        },
        {
          text: 'Min',
          value: 'min_price',
          sortable: 'min_price_cents'
        },
        {
          text: 'Max',
          value: 'max_price',
          sortable: 'max_price_cents'
        },
        {
          text: 'Repriced At',
          value: 'repriced_at',
          sortable: 'repriced_at'
        },
        {
          text: 'Competition',
          value: 'competition',
        },
        {
          text: 'Inventory',
          value: 'quantity',
          sortable: 'quantity'
        },
        {
          text: 'Velocity',
          value: 'salesVelocity30',
          sortable: 'sales_velocity_30'
        },
        {
          text: 'Rank',
          value: 'sales_rank',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      filters: BASE_FILTERS,
      pagination: {
        page: 1,
        per: 50,
        last_page: 1,
        total: 0,
      },
      sortBy: {
        sort_by: 'updated_at',
        sort_direction: 'desc',
      },
    }
  },
  watch: {
    'selectAll':function(value){
      this.selectFiltered = false
      if(value){
        this.selected = []
        this.listings.forEach(listing => {
          this.selected.push(listing.id)
        })
      }else{
        this.selected = []
      }
    },
    '$route.query': function (newValue, oldValue) {
      // Remove the empty query params
      let newQuery = Object.assign({}, this.$route.query)
      console.log(newQuery)
      let paramNames = Object.keys(newQuery)
      let dirty = false
      paramNames.forEach((param) => {
        let value = newQuery[param]
        if (value === null || (value + '').length < 1) {
          delete newQuery[param]
          dirty = true
        }
      })
      if (dirty) {
        this.$router.push({query: newQuery})
        console.log('changed query')
      }
    },
    'pagination.page': function(newValue){
      this.$router.push( { query: {...this.$route.query, ...{page: newValue} }})
      this.fetchListings(newValue)
    },
    filters: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.clearSelection()
        this.fetchListings(1)
      },
      deep: true,
    },
    sortBy: {
      handler(newValue){
        let params = {}
        for (const [key, value] of Object.entries(newValue)) {
          if (value && Array.isArray(value) && value.length > 0) {
            params[key] = value.toString()
          }else{
            params[key] = value
          }
        }
        this.$router.push( { query: {...this.$route.query, ...params }})
        this.clearSelection()
        this.fetchListings(1)
      },
      deep: true,
    },
  },
  computed: {
    filterOptions(){
      return [
        {
          name: 'brand',
          display: 'Brand',
          options: this.brandOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details',
        },
        {
          name: 'stock_range',
          display: 'Stock Range',
          type: 'number-range',
          verb: 'is',
          group: 'Details',
        },
        {
          name: 'created_at',
          display: 'Created At',
          type: 'date-range',
          verb: 'is in between',
          group: 'Details',
        },
        {
          name: 'updated_at',
          display: 'Updated At',
          type: 'date-range',
          verb: 'is in between',
          group: 'Details',
        },
        {
          name: 'price',
          display: 'Price',
          type: 'number-range',
          verb: 'is',
          group: 'Pricing',
        },
        {
          name: 'sales_velocity_30',
          display: 'Velocity 30 days',
          type: 'number-range',
          verb: 'is',
          group: 'Details',
        },
        {
          name: 'min_price',
          display: 'Minimum Price',
          type: 'number-range',
          verb: 'is',
          group: 'Pricing',
        },
        {
          name: 'max_price',
          display: 'Maximum Price',
          type: 'number-range',
          verb: 'is',
          group: 'Pricing',
        },
        {
          name: 'sales_channel',
          display: 'Sales Channel',
          options: this.salesChannelOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'buybox_eligibility',
          display: 'Buy Box Eligibility',
          options: this.buyboxEligibilityOptions,
          type: 'single',
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'buybox_status',
          display: 'Buy Box Status',
          options: this.buyboxStatusOptions,
          type: 'single',
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'strategy',
          display: 'Strategy',
          options: this.strategyOptions,
          type: 'multiple',
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'quantity',
          display: 'Stock Status',
          type: 'single',
          options: this.quantityOptions,
          verb: 'is',
          group: 'Status'
        },
        {
          name: 'under_review',
          display: 'Under Review',
          type: 'single',
          options: this.underReviewOptions,
          verb: 'is',
          group: 'Status'
        },
        {
          name: 'fulfillment_network',
          display: 'Fulfillment Network',
          type: 'single',
          options: this.fulfillmentNetworkOptions,
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'price_below_cost',
          display: 'Potential Profit Loss',
          type: 'single',
          options: this.potentialProfitLossOptions,
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'competition_price',
          display: 'Competition Price',
          type: 'single',
          options: this.competitionPriceOptions,
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'cost_presence',
          display: 'Cost',
          type: 'single',
          options: this.costPresenceOptions,
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'not_repricing_status',
          display: 'Reason For Not Repricing',
          type: 'single',
          options: this.notRepricingStatusOptions,
          verb: 'is',
          group: 'Pricing'
        },
        {
          name: 'status',
          display: 'Status',
          type: 'multiple',
          options: this.statusOptions,
          verb: 'is',
          group: 'Status'
        },
        {
          name: 'inventory_errors',
          display: 'Inventory Errors',
          type: 'single',
          options: this.inventoryErrorsOptions,
          verb: 'is',
          group: 'Status'
        },
        {
          name: 'error_category',
          display: 'Error category',
          type: 'multiple',
          options: this.errorCategoryOptions,
          verb: 'is',
          group: 'Status'
        },
        {
          name: 'retire_mode',
          display: 'Retire mode',
          type: 'single',
          options: this.retireModeOptions,
          verb: 'is',
          group: 'Status'
        },
        {
          name: 'term',
          display: 'Search Term',
          type: 'hidden',
          verb: 'is',
          group: 'Details'
        },
        {
          name: 'sort_by',
          display: 'Sort',
          type: 'alwaysHidden',
          verb: 'by',
          group: 'Details'
        },
        {
          name: 'sort_direction',
          display: 'Sort direction',
          type: 'alwaysHidden',
          verb: '',
          group: 'Details'
        },
      ]
    },
    retireModeOptions(){
      let options = []
      options.push({
        label: "Parked",
        code: 'park',
      })
      options.push({
        label: "Not Parked",
        code: 'not_park',
      })
      return options
    },
    inventoryErrorsOptions(){
      let options = []
      options.push({
        label: "Has Errors",
        code: 'has_errors',
      })
      options.push({
        label: "Has No Errors",
        code: 'has_no_errors',
      })
      return options
    },
    errorCategoryOptions(){
      let options = [
        {
          label: "Attributes missing",
          code: 'DATA_MISSING'
        },
        {
          label: "Too many characters",
          code: 'DATA_TOO_LONG'
        },
        {
          label: "Too few characters",
          code: 'DATA_TOO_SHORT'
        },
        {
          label: "Invalid attributes",
          code: 'DATA_INVALID'
        },
        {
          label: "Not allowed to create/modify new ASIN",
          code: 'NOT_ALLOWED_TO_CREATE_ASIN'
        },
        {
          label: "Invalid Product ID (barcode)",
          code: 'INVALID_PRODUCT_ID'
        },
        {
          label: "Marketplace system error",
          code: 'MARKETPLACE_ERROR'
        },
        {
          label: "Wrong ASIN submitted",
          code: 'WRONG_ASIN'
        },
        {
          label: "Offer cannot be modified",
          code: 'OFFER_ERROR'
        },
        {
          label: "Not authorized to sell",
          code: 'NOT_AUTHORIZED'
        },
        {
          label: "Approval needed",
          code: 'APPROVAL_NEEDED'
        },
        {
          label: "Duplicate SKU",
          code: 'DUPLICATE_SKU'
        },
        {
          label: "Offer ended",
          code: 'OFFER_ENDED'
        },
        {
          label: "Pricing Error",
          code: 'PRICING_ERROR'
        },
        {
          label: "Compliance",
          code: 'COMPLIANCE'
        },
        {
          label: "Unidentified error",
          code: 'OTHER'
        }
      ]
      return options
    },
    quantityOptions(){
      let options = []
      options.push({
        label: "With Stock",
        code: 'with',
      })
      options.push({
        label: "Without Stock",
        code: 'without',
      })
      return options
    },
    costPresenceOptions(){
      let options = []
      options.push({
        label: "present",
        code: 'present',
      })
      options.push({
        label: "not present",
        code: 'not_present',
      })
      return options
    },
    buyboxEligibilityOptions(){
      let options = []
      options.push({
        label: "Eligible",
        code: 'eligible',
      })
      options.push({
        label: "Not Eligible",
        code: 'not_eligible',
      })
      return options
    },
    buyboxStatusOptions(){
      let options = []
      options.push({
        label: "Won Buy Box",
        code: 'won',
      })
      options.push({
        label: "Lost Buy Box",
        code: 'lost',
      })
      options.push({
        label: "No Competition",
        code: "no_competition"
      })
      return options
    },
    currentPriceOptions(){
      let options = []
      options.push({
        label: "At min price",
        code: 'at_min_price',
      })
      options.push({
        label: "At max price",
        code: 'at_max_price',
      })
      return options
    },
    notRepricingStatusOptions(){
      let options = []
      options.push({
        label: "No min price",
        code: 'no_min_price',
      })
      options.push({
        label: "Listing Suppressed",
        code: 'suppressed',
      })
      return options
    },
    competitionPriceOptions(){
      let options = []
      options.push({
        label: "Below My Min",
        code: 'below_min',
      })
      options.push({
        label: "Above My Max",
        code: 'above_max',
      })
      return options
    },
    fulfillmentNetworkOptions(){
      let options = []
      options.push({
        label: "MFN",
        code: 'MFN',
      })
      options.push({
        label: "FBA",
        code: 'FBA',
      })
      options.push({
        label: "WFS",
        code: 'WFS',
      })
      return options
    },
    underReviewOptions(){
      let options = []
      options.push({
        label: "true",
        code: 'true',
      })
      options.push({
        label: "false",
        code: 'false',
      })
      return options
    },
    potentialProfitLossOptions(){
      let options = []
      options.push({
        label: "true",
        code: 'true',
      })
      options.push({
        label: "false",
        code: 'false',
      })
      return options
    },
    brandOptions(){
      let options = []
      this.$store.getters['brands/all'].forEach((brand) => {
        options.push({
          label: brand.name,
          code: brand.name,
        })
      })
      return options
    },
    categoryOptions(){
      let options = []
      this.$store.getters['categories/all'].forEach((category) => {
        options.push({
          label: category.name,
          code: category.id,
        })
      })
      return options
    },
    salesChannelOptions(){
      let options = []
      this.salesChannels.forEach((salesChannel) => {
        options.push({
          label: salesChannel.name,
          code: salesChannel.id,
        })
      })
      return options
    },
    strategyOptions(){
      let options = []
      this.$store.getters['strategies/all'].forEach((strategy) => {
        options.push({
          label: strategy.name,
          code: strategy.id,
        })
      })
      return options
    },
    statusOptions(){
      let options = []
      this.$enums.listingStatuses.forEach((status) => {
        options.push({
          code: status.code,
          label: status.name+" ("+this.searchCount[status.code]+")"
        })
      })
      console.log(options)
      return options
    }
  },
  beforeMount(){
    this.setFilters()
  },
  unmounted(){
    this.filters = BASE_FILTERS
  },
  mounted(){
    const vm = this
    this.fetchSalesChannels()
    this.$store.dispatch('strategies/fetch')

    this.$eventBus.on('listing:created', () => {
      this.fetchListings(this.pagination.page)
    })

    this.$eventBus.on('listing:updated', () => {
      this.fetchListings(this.pagination.page)
    })
  },
  
  methods: {
    async fetchListings(page){
      this.loading = true
      this.clearSelection()
      try {
        this.listings = []
        let response = await this.$repositories.listings.get({...this.filters, ...this.pagination, ...{page: page}, ...{exclude_product: true}})
        this.listings = response.data
        this.pagination.per = response.pagination.perPage
        this.pagination.page = response.pagination.currentPage
        this.pagination.total = response.pagination.total
        this.pagination.last_page = response.pagination.totalPages
        this.searchCount = response.pagination.searchCount
      } catch (error) {
        console.log(error)
      }finally {
        nextTick(() => this.loading = false)
      }
    },
    async fetchSalesChannels(){
      try {
        let response = await this.$repositories.salesChannels.get({per: 10000, sort_by: 'name', sort_direction: 'asc'})
        this.salesChannels = response.data
      } catch (error) {
        console.log(error)
      }
    },
    search(){
      this.setFilters()
      this.fetchListings()
    },
    applyFilters(){
      this.setFilters()
      this.fetchListings()
    },
    removeFilter(filters){
      this.filters = filters
    },
    clearSelection(){
      this.selected = []
      this.selectFiltered = false
      this.selectAll = false
    },
    debounceTerm: _.debounce(function (event) {
      this.filters.term = event.target.value
    }, 800),

    openRetireModal(selected, selectFiltered){
      this.$refs.listingRetireModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length,
      })
    },

    openUnretireModal(selected, selectFiltered){
      this.$refs.listingUnretireModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length,
      })
    },

    openDeleteModal(selected, selectFiltered){
      this.$refs.listingDeleteModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length,
      })
    },

    openPublishModal(selected, selectFiltered){
      this.$refs.listingPublishModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length,
      })
    },

    openChangeStrategyModal(selected, selectFiltered){
      this.$refs.listingChangeStrategyModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length,
      })
    },

    openAdjustPriceModal(column, selected, selectFiltered){
      this.$refs.listingAdjustPricesModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length,
        column_name: column
      })
    },

    openDiscountModal(selected, selectFiltered){
      this.$refs.listingDiscountModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}}, 
        total: selectFiltered ? this.pagination.total : selected.length,
      })
    },

    openSendToMaxModal(selected, selectFiltered){
      this.$refs.listingSendToMaxModal.open({
        filters: selectFiltered ? this.filters : {...this.filters, ...{id: selected}},
        total: selectFiltered ? this.pagination.total : selected.length,
      })
    },

    setFilters() {
      let params = this.$route.query;
      let filters = _.cloneDeep(BASE_FILTERS)

      if (params.term) {
        filters.term = params.term
      }

      if (params.quantity) {
        filters.quantity = params.quantity
      }else{
        filters.quantity = 'with'
      }

      if (params.status) {
        filters.status = params.status.split(",")
      }

      if (params.under_review) {
        filters.under_review = params.under_review
      }

      if (params.current_price) {
        filters.current_price = params.current_price
      }

      if (params.fulfillment_network) {
        filters.fulfillment_network = params.fulfillment_network
      }

      if (params.price_below_cost) {
        filters.price_below_cost = params.price_below_cost
      }
      
      if (params.cost_presence) {
        filters.cost_presence = params.cost_presence
      }

      if (params.not_repricing_status) {
        filters.not_repricing_status = params.not_repricing_status
      }

      if (params.competition_price) {
        filters.competition_price = params.competition_price
      }

      if (params.retire_mode) {
        filters.retire_mode = params.retire_mode
      }

      if (params.inventory_errors) {
        filters.inventory_errors = params.inventory_errors
      }

      if (params.buybox_status) {
        filters.buybox_status = params.buybox_status
      }

      if (params.buybox_eligibility) {
        filters.buybox_eligibility = params.buybox_eligibility
      }

      if (params.department) {
        filters.department = params.department.split(",")
      }

      if (params.error_category) {
        filters.error_category = params.error_category.split(",")
      }

      if (params.sales_channel) {
        filters.sales_channel = params.sales_channel.split(",")
      }

      if (params.strategy) {
        filters.strategy = params.strategy.split(",")
      }

      if (params.brand) {
        filters.brand = params.brand.split(",")
      }

      if (params.created_at) {
        filters.created_at = params.created_at.split(",")
      }

      if (params.updated_at) {
        filters.updated_at = params.updated_at.split(",")
      }

      if (params.parentage_type) {
        filters.parentage_type = params.parentage_type.split(",")
      }

      if (params.stock_range) {
        filters.stock_range = params.stock_range.split(",")
      }

      if (params.price) {
        filters.price = params.price.split(",")
      }

      if (params.sales_velocity_30) {
        filters.sales_velocity_30 = params.sales_velocity_30.split(",")
      }

      if (params.missing_on_channel) {
        filters.missing_on_channel = params.missing_on_channel.split(",")
      }

      if (params.page) {
        this.pagination.page = parseInt(params.page)
      }else{
        this.pagination.page = 1
      }

      if (params.only_with_quantity) {
        filters.only_with_quantity = params.only_with_quantity
      }

      if (params.without_image) {
        filters.without_image = params.without_image
      }

      if (params.sort_by) {
        filters.sort_by = params.sort_by
      }

      if (params.sort_direction) {
        filters.sort_direction = params.sort_direction
      }

      this.filters = filters
    },
  }
}
</script>

<template>
  <div>
    <PageHeader>
      <template v-slot:header>
        Listings
      </template>
      <template v-slot:actions>
        <ExportButton resource="listings" :filters="filters" :disabled="loading"/>
      </template>
    </PageHeader>
    <div class="sm:mx-2 md:mx-10 my-4">
      <div class="w-full flex justify-between pb-5">
        <div class="flex items-center space-x-4">
          <FilterButton :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" />
          <FiltersBar :filterOptions="filterOptions" :filters="filters" @update:filters="filters = $event" class="py-1"/>
        </div>
        <div>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon class="w-4 h-4 text-gray-500"/>
            </div>
            <Input :value="filters.term" v-on:input="debounceTerm" class="pl-8" :disabled="loading" placeholder="Search...." />
          </div>
        </div>
      </div>

      <div class="w-full">
        <TTable :headers="listingHeaders" :items="listings" :loading="loading" :filters="filters" :pagination="pagination" style="height: 80vh;">
          <template #actions="{ selected, selectFiltered}">
            <Button color="white" size="sm" @click="openChangeStrategyModal(selected, selectFiltered)">
              Change Strategy
            </Button>
            <Menu as="div" class="relative inline-block text-left">
              <div>
                <MenuButton as="template">
                  <Button color="white" size="sm">
                    {{ $t('Change Prices') }}
                  </Button>
                </MenuButton>
              </div>

              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute top-10 mt-2 w-72 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-300">
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <a @click="openAdjustPriceModal('cost', selected, selectFiltered)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        {{ $t('Change Cost') }}
                      </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a @click="openAdjustPriceModal('price', selected, selectFiltered)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        {{ $t('Change Price') }}
                      </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a @click="openAdjustPriceModal('min_price', selected, selectFiltered)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        {{ $t('Change Min Price') }}
                      </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a @click="openAdjustPriceModal('max_price', selected, selectFiltered)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        {{ $t('Change Max Price') }}
                      </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a @click="openDiscountModal(selected, selectFiltered)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        {{ $t('Apply Discount') }}
                      </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a @click="openSendToMaxModal(selected, selectFiltered)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        {{ $t('Send to Max') }}
                      </a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
            <Button color="green" size="sm" @click="openPublishModal(selected, selectFiltered)">
              Publish
            </Button>
            <Button color="blue" size="sm" @click="openRetireModal(selected, selectFiltered)" class="flex items-center space-x-1">
              <SquareParkingIcon class="w-5 h-5" />
              <span>
                Park
              </span>
            </Button>
            <Button color="retired" size="sm" @click="openUnretireModal(selected, selectFiltered)" class="flex items-center space-x-1">
              <SquareParkingSlashIcon class="w-5 h-5" />
              <span>
                Unpark
              </span>
            </Button>
            <Button color="deleting" size="sm" @click="openDeleteModal(selected, selectFiltered)">
              Delete
            </Button>
          </template>
          <template v-slot:[`item.sku`]="{ item }">
            <div class="space-y-1">
              <div class="flex space-x-1 items-center w-56 ">
                <div class="text-sm text-gray-500 truncate">
                  {{ item.sku }}
                </div>
                <CopyButton :copy="item.sku" class="w-3 h-3" />
              </div>
              <div class="flex items-center space-x-2">
                <img :src="item.salesChannel.platform.logo" class="w-6" v-tooltip="item.salesChannel.name"/>
                <img :src="item.salesChannel.platform.countryFlag" class="h-6" v-tooltip="item.salesChannel.name"/>
                <ListingFulfillmentBadge :listing="item" />
                <BoxOpenIcon class="w-5 h-5 text-green-400" v-tooltip="'Listing is Buy Box Eligible'" v-if="item.eligibleForBuyBox"/>
                <BoxIcon class="w-5 h-5 text-red-400" v-tooltip="'Listing is not Buy Box Eligible'" v-else/>
                <Badge color="gray" small class="flex items-center space-x-1" v-if="item.listingId">
                  <span>{{ item.listingId }}</span>
                  <CopyButton class="w-3 h-3" :copy="item.listingId" />
                </Badge>
              </div>
            </div>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <div class="truncate w-56 space-y-1">
              <router-link :to="{name: 'dashboard.listing.index', params: { listingId: item.id }}" target="_blank" class=" text-blue-600 hover:text-blue-400 text-left cursor-pointer" v-tooltip="item.name">
                {{ item.name }}
              </router-link>
              <div class="flex items-center space-x-2 align-middle">
                <ListingStatusBadge :status="item.status" />
                <ListingSuppressedBadge v-if="(item.status == 'failed' || item.status == 'unpublished') && item.suppressed"/>
                <span>
                  <a :href="item.salesChannelUrl" target="_blank" class="text-blue-500 hover:text-blue-800 cursor-pointer pt-3" v-if="item.salesChannelUrl.length > 0" v-tooltip="'View listing on marketplace'">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
                  </a>
                </span>
                <SquareParkingIcon class="h-5 w-5 text-blue-500" v-tooltip="'Parked by '+item.retireAuthor+' with reason: '+item.retireReason" v-if="item.isParked()"/>
              </div>
            </div>
          </template>

          <template v-slot:[`item.price`]="{ item }">
            <ListingPriceSmallForm :listing="item" column="price"/>
          </template>

          <template v-slot:[`item.min_price`]="{ item }">
            <ListingPriceSmallForm :listing="item" column="minPrice"/>
          </template>

          <template v-slot:[`item.cost`]="{ item }">
            <ListingPriceSmallForm :listing="item" column="cost"/>
          </template>

          <template v-slot:[`item.quantity`]="{ item }">
            <div class="flex items-center space-x-1">
              <span class="text-gray-700 text-center">{{ item.quantity }}</span>
              <span>
                <TriangleExclamationIcon class="w-5 h-5 text-orange-500" v-tooltip="item.inventoryErrors.toString()" v-if="item.inventoryErrors != null"/>
              </span>
            </div>
          </template>

          <template v-slot:[`item.strategy`]="{ item }">
            <ListingStrategySmallForm :listing="item"/>
          </template>

          <template v-slot:[`item.max_price`]="{ item }">
            <ListingPriceSmallForm :listing="item" column="maxPrice"/>
          </template>

          <template v-slot:[`item.sales_velocity_30`]="{ item }">
            <span :class="[item.salesVelocity30 > 0 ? 'text-gray-700' : 'text-gray-400']" v-tooltip="'Number of sales in the last 30 days'">
              {{ item.salesVelocity30 }}
            </span>
          </template>

          <template v-slot:[`item.competition_price`]="{ item }">
            <span :class="[item.lowestOffer > 0 ? 'text-gray-700 hover:text-gray-500' : 'text-gray-400 hover:text-gray-600']" v-tooltip="'Click to change the max price.'" v-if="item.lowestOffer > 0">
              {{ $filters.currency.format(item.lowestOffer, item.currency) }}
            </span>
          </template>

          <template v-slot:[`item.is_buybox_winner`]="{ item }">
            <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium" v-if="item.isBuyBoxWinner == null">
              <CircleXmarkIcon class="w-5 h-5 text-blue-500" v-tooltip="'The competition did not change the price on this listing.'"/>
            </span>
            <span class="text-center" v-else-if="item.isBuyBoxWinner == false">
              <CircleCheckIcon class="w-5 h-5 text-red-500" v-tooltip="'You lost the buy box.'"/>
            </span>
            <span class="text-center" v-else-if="item.isBuyBoxWinner == true">
              <CircleCheckIcon class="w-5 h-5 text-green-500" v-tooltip="'You won the buy box.'"/>
            </span>
          </template>
          
          <template v-slot:[`item.sales_rank`]="{ item }">
            <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium" v-if="item.salesRank == null">
              N/A
            </span>
            <div class="flex flex-col" v-else>
              <p class="text-sm text-gray-700">{{ item.salesRank.rank }}</p>
              <p class="text-sm text-gray-400">{{ item.salesRank.product_category }}</p>
            </div>
          </template>

          <template v-slot:[`item.repriced_at`]="{ item }">
            <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium" v-if="item.repricedAt == null">
              N/A
            </span>
            <div v-else>
              <p class="text-sm text-gray-700">{{ $filters.date.dateTimeFormat(item.repricedAt) }}</p>
            </div>
          </template>

          <template v-slot:[`item.competition`]="{ item }">
            <span class="flex items-center justify-center" v-if="item.competitionPrice > 0">
              <ListingCompetitionButton :competitionDetails="item.competitionDetails">
                {{ $filters.currency.format(item.competitionPrice, item.currency) }}
              </ListingCompetitionButton>
            </span>
            <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium text-gray-500" v-else>
              N/A
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="flex items-center space-x-3">
              <a class="text-blue-500 hover:text-blue-700" @click="$refs.productFormModal.open(item)" v-tooltip="'Edit'">
                <PencilIcon class="w-6 h-6"/>
              </a>
              <router-link :to="{name: 'dashboard.listing.index', params: { listingId: item.id }}" class="text-gray-500 hover:text-gray-700" v-tooltip="'View'">
                <EyeIcon class="w-6 h-6"/>
              </router-link>
              <a class="text-red-500 hover:text-red-700" @click="$refs.productFormModal.open(item)" v-tooltip="'Delete'">
                <TrashIcon class="w-6 h-6"/>
              </a>
            </div>
          </template>
          <template v-slot:pagination>
            <Pagination class="sticky bottom-0" :totalItems="pagination.total" :lastPage="pagination.last_page" :perPage="pagination.per" v-model="pagination.page"/>
          </template>
        </TTable>
      </div>
    </div>

    <ListingRetireModal ref="listingRetireModal" />
    <ListingUnretireModal ref="listingUnretireModal" />
    <ListingPublishModal ref="listingPublishModal" />
    <ListingAdjustPricesModal ref="listingAdjustPricesModal" />
    <ListingChangeStrategyModal ref="listingChangeStrategyModal" />
    <ListingDeleteModal ref="listingDeleteModal" />
    <ListingSendToMaxModal ref="listingSendToMaxModal" />
    <ListingDiscountModal ref="listingDiscountModal" />
  </div>
</template>