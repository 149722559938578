import BaseModel from "@/models/BaseModel"
import Product from '@/models/Product'
import SalesChannel from '@/models/SalesChannel'
import ListingGallery from '@/models/ListingGallery'
import PriceHistory from '@/models/PriceHistory'
import Currency from '@/models/Currency'

export default class Listing extends BaseModel{
  constructor(json) {
    super(json)

    this.id = null
    this.brand = ''
    this.chemicals = false
    this.competitionDetails = {}
    this.competitionPrice = 0
    this.copyFrom = ''
    this.cost = 0
    this.createdAt = null
    this.currency = null
    this.description = ''
    this.disclaimers = []
    this.electronicsIndicator = false
    this.endDate = null
    this.errors = ''
    this.fba = false
    this.eligibleForBuyBox = false
    this.fieldListingAttributes = null
    this.fields = {}
    this.fulfillmentLagTime = 1
    this.fulfillmentNetwork = 'MFN'
    this.forceInventory = false
    this.quantity = 0
    this.keyFeatures = ['', '', '', '', '', '']
    this.listingGalleries = null
    this.listingId = null
    this.locked = false
    this.lowestOffer = 0
    this.lowestOfferAt = null
    this.manufacturer = ''
    this.map = 0
    this.marketplaceQuantity = 0
    this.maxPrice = 0
    this.inventoryErrors = null
    this.minimum_advertised_price = 0
    this.minPrice = 0
    this.modelNumber = ''
    this.msrp = 0
    this.multipackQuantity = 0
    this.mustShipAlone = false
    this.name = ''
    this.platformCategoryId = null
    this.price = 0
    this.isBuyBoxWinner = null
    this.priceHistory = null
    this.productId = null
    this.productIdType = 'UPC'
    this.productIdUpdate = false
    this.productIdValue = ''
    this.productTaxCode = null
    this.reviewsCount = 0
    this.salesChannelId = null
    this.salesChannelStatus = ''
    this.salesChannelStatusReason = ''
    this.salesChannelUrl = ''
    this.salesRank = null
    this.salesVelocity30 = 0
    this.setupByMatch = false
    this.shipsInOriginalPackage = true
    this.skipValidateFieldsSchema = false
    this.sku = ''
    this.skuUpdate = false
    this.startDate = null
    this.status = ''
    this.strategyId = null
    this.suppressed = false
    this.underReview = false
    this.updatedAt = null
    this.weightUnit = 'oz'
    this.weightValue = 0

    this.repricedAt = null

    this.retireMode = ''
    this.retireReason = ''
    this.retireAuthor = ''
    this.retiredAt = 0

    this.deleteReason = ''
    this.deleteAuthor = ''
    this.deletedAt = 0
  }

  static fromJson(json){
    let listing = new this()
    listing.brand = json.brand
    listing.competitionDetails = json.competition_details
    listing.competitionPrice = json.competition_price
    listing.cost = parseFloat(json.cost)
    listing.createdAt = json.created_at
    listing.currency = Currency.fromJson(json.currency)
    listing.description = json.description
    listing.disclaimers = json.disclaimers
    listing.end_date = json.end_date
    listing.errors = json.errors
    listing.fields = json.fields
    listing.fulfillmentLagTime = json.fulfillment_lag_time
    listing.fulfillmentNetwork = json.fulfillment_network
    listing.id = json.id
    listing.inventoryErrors = json.inventory_errors
    listing.quantity = json.quantity
    listing.eligibleForBuyBox = json.eligible_for_buybox
    listing.isBuyBoxWinner = json.is_buybox_winner
    listing.listingGalleries = this.initRelation(json.listing_galleries, ListingGallery, null)
    listing.listingId = json.listing_id
    listing.keyFeatures = json.key_features
    listing.locked = json.locked
    listing.lowestOffer = parseFloat(json.lowest_offer)
    listing.lowestOfferAt = json.lowest_offer_at
    listing.mainImageId = json.main_image_id
    listing.manufacturer = json.manufacturer
    listing.map = json.minimum_advertised_price
    listing.marketplaceQuantity = json.marketplace_quantity
    listing.maxPrice = parseFloat(json.max_price)
    listing.minPrice = parseFloat(json.min_price)
    listing.modelNumber = json.model_number
    listing.mustShipAlone = json.must_ship_alone
    listing.name = json.name
    listing.platformCategoryId = json.platform_category_id
    listing.price = parseFloat(json.price)
    listing.priceHistory = this.initRelation(json.price_history, PriceHistory, null)
    listing.product = this.initRelation(json.product, Product, null)
    listing.productId = json.product_id
    listing.productIdType = json.product_id_type
    listing.productIdValue = json.product_id_value
    listing.productTaxCode = json.product_tax_code
    listing.productIdUpdate = json.product_id_update
    listing.reviewsCount = json.reviews_count
    listing.salesChannel = this.initRelation(json.sales_channel, SalesChannel, null)
    listing.salesChannelId = json.sales_channel_id
    listing.salesChannelStatus = json.sales_channel_status
    listing.salesChannelStatusReason = json.sales_channel_status_reason
    listing.salesChannelUrl = json.sales_channel_url
    listing.salesRank = json.sales_rank
    listing.salesVelocity30 = json.sales_velocity_30
    listing.setupByMatch = json.setup_by_match
    listing.shipsInOriginalPackage = json.ships_in_original_package
    listing.sku = json.sku
    listing.skuUpdate = json.sku_update
    listing.startDate = json.start_date
    listing.status = json.status
    listing.strategyId = json.strategy_id
    listing.suppressed = json.suppressed
    listing.updatedAt = json.updated_at
    listing.weightUnit = json.weight_unit
    listing.weightValue = json.weight_value
    listing.retireMode = json.retire_mode
    listing.retireReason = json.retire_reason
    listing.retireAuthor = json.retire_author
    listing.retiredAt = json.retired_at
    listing.repricedAt = json.repriced_at

    listing.deleteReason = json.delete_reason
    listing.deletedBy = json.deleted_by
    listing.deletedAt = json.deleted_at

    return listing
  }

  toJson(){
    return {
      listing: {
        brand: this.brand,
        cost: this.cost,
        description: this.description,
        fields: this.fields,
        fulfillment_lag_time: this.fulfillmentLagTime,
        fulfillment_network: this.fulfillmentNetwork,
        force_inventory: this.forceInventory,
        listing_id: this.listingId,
        main_image_id: this.mainImageId,
        manufacturer: this.manufacturer,
        max_price: this.maxPrice,
        min_price: this.minPrice,
        model_number: this.modelNumber,
        key_features: this.keyFeatures,
        msrp: this.msrp,
        name: this.name,
        platform_category_id: this.platformCategoryId,
        price: this.price,
        product_id_type: this.productIdType,
        product_id_value: this.productIdValue,
        product_id: this.productId,
        product_id_update: this.productIdUpdate,
        sales_channel_id: this.salesChannelId,
        skip_validate_fields_schema: this.skipValidateFieldsSchema,
        sku: this.sku,
        status: this.status,
        strategy_id: this.strategyId,
        weight_unit: this.weightUnit,
        weight_value: this.weightValue,
        sku_update: this.skuUpdate
      }
    }
  }

  toJsonPrice(){
    return {
      listing: {
        max_price: this.maxPrice,
        min_price: this.minPrice,
        price: this.price
      }
    }
  }

  availableStatusesForChange(){
    let options = []

    if(this.status == 'published' || this.status == 'failed' || this.status == 'unpublished'){
      options = ['published']
    }

    if(this.status == 'draft'){
      options = ['ready_to_publish']
    }

    if(this.status == 'deleted'){
      options = ['in_progress']
    }

    if(this.status == 'ready_to_publish'){
      options = ['in_progress']
    }

    if(this.status == 'retired' && this.listingId){
      options = ['published']
    }

    if(this.status == 'retired' && !this.listingId){
      options = ['draft']
    }

    return options
  }

  isIdsLocked(){
    return this.status == 'published' || (this.status == 'failed' && this.listingId) || this.status == 'unpublished' || this.status == 'deleting'
  }

  isParked(){
    return this.retireMode == 'park'
  }
}
